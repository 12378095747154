import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "avg", "sum", "category" ]
  connect() {
  	var categories = this.categoryTargets
  	
  	categories.forEach(function(category) {
  		highlightMaxAvg(category)
  	})
}
}

function highlightMaxAvg( category ) {
	var array = []
	var list_of_averages = Array.from(category.getElementsByTagName("span"))
	console.log(list_of_averages)
	list_of_averages.forEach(function(element) {
  		array.push(parseFloat(element.innerHTML))
  	});
  	var max_avg = Math.max(...array)
  	console.log(max_avg)
  	let found_bit = getElementsByText(`${max_avg.toFixed(2)}`)
  	found_bit.forEach(function(e) {
  	e.classList.add("bg-success")
  	e.classList.add("text-white")
  })	
}



function getElementsByText(str, tag = 'span') {
  return Array.prototype.slice.call(document.getElementsByTagName(tag)).filter(el => el.textContent.trim() === str.trim());
}

