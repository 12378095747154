// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "links", "template", "templatemed" ]

  connect() {
  	let selectors = $('.nested-fields')
  	let arrayLength = selectors.length
   	let i = 0
   	 for ( i = 0; i < arrayLength; i++){
   	 	let wrapper = selectors[i]
   	 	let selector = wrapper.querySelector(".domain_definition_selector")
   	 	let selected = selector.querySelector("select").value == ""
   	 	if (selected == false) {
   	 		let btn = wrapper.querySelector(".btn")
   			btn.remove()
   	 	} 
   	 	console.log(selected)
   	 }
   		
   	
  }

   add_association(event){
   	event.preventDefault()
   	console.log("clicked")
   	var content = this.templateTarget.innerHTML.replace(/NEW_RECORD/g, new Date().getTime())
      console.log(content) 
   	this.linksTarget.insertAdjacentHTML('beforebegin', content )
   }

   add_med_association(event){
      event.preventDefault()
      console.log("med_add_click")
      var content = this.templatemedTarget.innerHTML.replace(/NEW_RECORD/g, new Date().getTime()) 
      console.log(content)
      this.linksTarget.insertAdjacentHTML('beforebegin', content )
   }

   remove_med_association(event) {
       event.preventDefault()
       let wrapper = event.target.closest(".nested-med-fields")
       console.log(wrapper)
       if (wrapper.dataset.newRecord == "true" ) {
         wrapper.remove()
       } else {
         wrapper.querySelector("input[name*='_destroy']").value = 1
         wrapper.style.display = 'none'

       }
   }

   remove_association(event) {
   	 event.preventDefault()
   	 let wrapper = event.target.closest(".nested-fields")
       console.log(wrapper)
   	 if (wrapper.dataset.newRecord == "true" ) {
   	 	wrapper.remove()
   	 } else {
   	 	wrapper.querySelector("input[name*='_destroy']").value = 1
   	 	wrapper.style.display = 'none'

   	 }
   }

   remove_selector(event) {
   	event.preventDefault()
   	let wrapper = event.target.closest(".nested-fields")
   	let selector = wrapper.querySelector(".domain_definition_selector")
   	let new_fields = wrapper.querySelector(".new_domain_definition_fields")
   	let selected = selector.querySelector("select").value == ""

   	selector.style.display = 'none'
   	new_fields.style.display = 'block'
   	event.target.style.display ='none'

   	console.log(selected)
   }

   domain_def_selected(event) {
   	let wrapper = event.target.closest(".nested-fields")
   	let btn = wrapper.querySelector(".btn")
   	btn.remove()
   }

}
