import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "selector", "simulations", "scores", "event_selector", "button" ]
  connect() {
  	this.scoresTargets.forEach((score) => { 
      const buttons = score.closest('.list-group-item').querySelectorAll(".score-button")
        buttons.forEach((button) => {
          const button_value =  parseInt(button.innerHTML, 10)
          if(button_value == score.value) {
            button.classList.add("score-button-selected")
          } 
        })

        setButtonClass(score.closest('.card'))

      })


  }

buttonPress(e) {
  const card = e.target.closest(".card")
  const pressed_button = e.target
  const button_value = parseInt(pressed_button.innerHTML, 10)
  const parent_list_item = pressed_button.closest('.list-group-item')
  const score_field = parent_list_item.querySelector('.score-input')
  const buttons = parent_list_item.querySelectorAll('.score-button')
  if ( score_field.value == button_value ) {
    score_field.value= null
    pressed_button.classList.remove("score-button-selected")
  } else {
    score_field.value=button_value
    buttons.forEach((button) => { button.classList.remove("score-button-selected") })
    pressed_button.classList.add("score-button-selected")
  }
  setButtonClass(card)
}

eventSelect(e) {
  const selected = e.target.value
  console.log(selected)
  fetch(`/teams_for_events/${selected}.json`)
    .then(response => response.text())
    .then(json => {this.set_selector(JSON.parse(json).teams, "rating_session_team_id", "Team")})
}
  
load(selected) {    
    fetch(`/simulations_for_teams/${selected}.json`)
      .then(response => response.text())
      .then(json => { 
        this.set_selector(JSON.parse(json).simulations, "rating_session_simulation_id", "Simulation" ) })
  }

teamselect (selector) {
    const selected = selector.target.value
  	console.log(selected)
    this.load(selected)
  }

scoreselect (scores) { 
  let wrapper = event.target.closest(".card")
  let query = `#${wrapper.id}`
  let button = document.querySelector(query)
  let selectors = wrapper.querySelectorAll("select")
  for (var selector of selectors) {
    let choice = selector.selectedOptions[0].value
    if ( !choice > 0 ) { 
      button.classList.remove('role-button-initial')
      button.classList.remove('role-button-complete')
      button.classList.add('role-button-started')
      return
    } else {
     button.classList.remove('role-button-initial')
     button.classList.remove('role-button-started')
     button.classList.add('role-button-complete')
    }
  }
}
  

  set_selector( json, target_selector, fetching ) {
    let options = json
    let html = `<option disabled selected value> -- select ${fetching}  -- </option>`
    options.forEach((el) => {
    html += `<option value=${el.id}>${el.name}</option>`
        })
    var selector = document.getElementById(target_selector)
    console.log(selector)
    selector.innerHTML=html
  }
}

function setButtonClass(card) {
  let query = `#${card.id}`
  let button = document.querySelector(query)
  let inputs = card.querySelectorAll(".score-input")
  let value = 0

  for (var input of inputs) {
    console.log(input.value)
    if ( input.value > 0 ) {
      value = value + 1
    } else {
      value = value + 0
    }
    }
    if(inputs.length == value) {
     button.classList.remove('role-button-initial')
     button.classList.remove('role-button-started')
     button.classList.add('role-button-complete')
    } else if (value == 0) {
     button.classList.add('role-button-initial')
     button.classList.remove('role-button-started')
     button.classList.remove('role-button-complete')
    } else  {
      button.classList.remove('role-button-initial')
      button.classList.remove('role-button-complete')
      button.classList.add('role-button-started')
    }

  }




