import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "template", "links" ]
  connect() {
  	console.log("hello role assignments!")
  }

  add_student(event){
   	event.preventDefault()
   	let parent = event.target.closest(".list-group-item")
   	let sim_role_id = parent.querySelectorAll(".sim-role-input")[0].value
   	var content = this.templateTarget.innerHTML.replace(/NEW_RECORD/g, new Date().getTime())
   	const button = event.target.closest('.links')
   	button.insertAdjacentHTML('beforebegin', content )
   	console.log(button.previousElementSibling.querySelector(".sim-role-input").value=sim_role_id)
   	
   }

   remove_student(event) {
     event.preventDefault()
     console.log("in remove action")
     let wrapper = event.target.closest(".nested-fields")
       console.log(wrapper)
     if (wrapper.dataset.newRecord == "true" ) {
      wrapper.remove()
     } else {
      wrapper.querySelector("input[name*='_destroy']").value = 1
      wrapper.style.display = 'none'

     }
   }

}