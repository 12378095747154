import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "checkbox" ]

  connect() {
  	const checkboxes = this.checkboxTargets;
  	checkboxes.forEach((checkbox) => {
  		if (checkbox.checked) {
  			addMutedClass(checkbox.closest('.list-group-item'));
  			
  		}
  	})
  }

  domainCheck (e) {
  	const domain_check = e.target.checked
  	if (domain_check) {
  		addMutedClass(e.target.closest('.list-group-item'))
  	} else {
  		removeMutedClass(e.target.closest('.list-group-item'))
  	}
  	showSaveButton()
  }

  categoryCheck(e) {
  	const category_check = e.target.checked
  	const domain_checkboxes = e.target.closest('.card').querySelectorAll('.domain-checkbox')
  	if (category_check) {
  		domain_checkboxes.forEach((checkbox) => { setCheckboxToTrue(checkbox)
  													addMutedClass(checkbox.closest('.list-group-item')) })
  	} else {
  		domain_checkboxes.forEach((checkbox) => { 	setCheckboxToFalse(checkbox)
  													removeMutedClass(checkbox.closest('.list-group-item')) })
  	}
  	showSaveButton()
  }

}

function addMutedClass(div) {
	div.classList.add('muted')
}

function removeMutedClass(div) {
	div.classList.remove('muted')
}

function setCheckboxToTrue(checkbox) {
	return checkbox.checked=true
}

function setCheckboxToFalse(checkbox) {
	return checkbox.checked=false
}

function showSaveButton() {
	const button = document.querySelector('.save-unhide')
	console.log(button)
	button.classList.add('show-button')
	button.classList.remove('save-unhide')
}


